// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ContentCardSwiper_HkRuC{display:none}@media(max-width:767.98px){.ContentCardSwiper_HkRuC{display:block;margin-top:2.4rem}}@media(max-width:1279.98px){.ContentCardSwiper_HkRuC._medium_kfye\\+{display:block;margin-top:2.4rem}}.ContentCardSwiper_HkRuC .swiper-wrapper{height:21.4rem}.ContentCardSwiper_HkRuC .cardsWrap_Eochr{display:flex;flex-direction:column;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "56px",
	"footer-h": "61.2rem",
	"mobile": "0",
	"tablet-sm": "768px",
	"tablet": "1024px",
	"laptop": "1280px",
	"desktop": "1440px",
	"large-desktop": "1920px",
	"ContentCardSwiper": "ContentCardSwiper_HkRuC",
	"_medium": "_medium_kfye+",
	"cardsWrap": "cardsWrap_Eochr"
};
module.exports = ___CSS_LOADER_EXPORT___;
